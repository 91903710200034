// DICLINA Translations
const locale = {
  STATUSGROUP: {
    buildconn: {
      de: "Hausanschluss",
      STATUS: {
        open: {
          de: "Noch nicht beauftragt",
          style: {
            color: "red",
            iconCssClass: "fas fa-exclamation",
          },
        },
        opened: {
          de: "In Auftrag gegeben",
          style: {
            color: "orange",
            iconCssClass: "fas fa-clipboard-check",
          },
        },
        pending: {
          de: "In Bau",
          style: {
            color: "yellow",
            iconCssClass: "fas fa-wrench",
          },
        },
        earthwork_finished: {
          de: "Tiefbau abgeschlossen",
          style: {
            color: "lightgreen",
            iconCssClass: "fas fa-wrench",
          },
        },
        accepted: {
          de: "Fertig gestellt",
          style: {
            color: "green",
            iconCssClass: "fas fa-flag-checkered",
          },
        },
        old_existingstat_homes_passed: {
          de: "Homes passed (Altbestand)",
          style: {
            color: "purple",
            iconCssClass: "fas fa-wrench"
          }
        },
        old_existingstat_earthwork_partially_finished: {
          de: "Tiefbau teilweise erfolgt (Altbestand)",
          style: {
            color: "lightblue",
            iconCssClass: "fas fa-wrench"
          }
        },
        old_existingstat_induction_is_set: {
          de: "Hauseinführung vorhanden (Altbestand)",
          style: {
            color: "lightgreen",
            iconCssClass: "fas fa-wrench"
          }
        }
      },
      requiredPermission: "ROLE_DSN_WRITE_BUILDING_CONNECTED_STATUS",
    },
    gnv: {
      de: "GNV",
      STATUS: {
        open: {
          de: "Nicht angeboten",
          style: {
            color: "orange",
            iconCssClass: "fas fa-exclamation",
          },
        },
        opened: {
          de: "Angeboten",
          style: {
            color: "yellow",
            iconCssClass: "fas fa-paper-plane",
          },
        },
        accepted: {
          de: "Abgeschlossen",
          style: {
            color: "green",
            iconCssClass: "fas fa-check",
          },
        },
        rejected: {
          de: "Abgelehnt",
          style: {
            color: "red",
            iconCssClass: "fas fa-times",
          },
        },
      },
      requiredPermission: "ROLE_DSN_WRITE_GNV_STATUS",
    },
    internet: {
      de: "Hatte zuvor Internet",
      STATUS: {
        open: {
          de: "Hatte Internet",
          style: {
            color: "green",
            iconCssClass: "fas fa-check",
          },
        },
        accepted: {
          de: "Hatte kein Internet",
          style: {
            color: "red",
            iconCssClass: "fas fa-times",
          },
        },
      },
      requiredPermission: "ROLE_DSN_WRITE_STATUS",
    },
    ne4: {
      de: "NE4 Bau",
      STATUS: {
        open: {
          de: "Nicht beauftragt",
          style: {
            color: "red",
            iconCssClass: "fas fa-exclamation",
          },
        },
        opened: {
          de: "Teiweise fertiggestellt",
          style: {
            color: "orange",
            iconCssClass: "fas fa-tasks",
          },
        },
        progress: {
          de: "In Bau",
          style: {
            color: "yellow",
            iconCssClass: "fas fa-wrench",
          },
        },
        commissioned: {
          de: "Beauftragt",
          style: {
            color: "lightgreen",
            iconCssClass: "fas fa-clipboard-check",
          },
        },
        completed: {
          de: "Fertiggestellt",
          style: {
            color: "green",
            iconCssClass: "fas fa-flag-checkered",
          },
        },
      },
      requiredPermission: "ROLE_DSN_WRITE_NE4_STATUS",
    },
    product: {
      de: "Produkt",
      STATUS: {
        open: {
          de: "Nicht angeboten",
          style: {
            color: "orange",
            iconCssClass: "fas fa-exclamation",
          },
        },
        opened: {
          de: "Angeboten",
          style: {
            color: "yellow",
            iconCssClass: "fas fa-paper-plane",
          },
        },
        accepted: {
          de: "Unterschrieben",
          style: {
            color: "lightgreen",
            iconCssClass: "fas fa-clipboard-check",
          },
        },
        rejected: {
          de: "Abgelehnt / Widerrufen",
          style: {
            color: "red",
            iconCssClass: "fas fa-times",
          },
        },
        active: {
          de: "Aktiv",
          style: {
            color: "green",
            iconCssClass: "fas fa-check",
          },
        },
      },
      requiredPermission: "ROLE_DSN_WRITE_STATUS",
    },
    offer: {
      de: "Vertrieb",
      STATUS: {
        open: {
          de: "Offen",
          style: {
            color: "orange",
            iconCssClass: "fas fa-exclamation",
          },
        },
        has_interest: {
          de: "Interesse besteht",
          style: {
            color: "green",
            iconCssClass: "fas fa-paper-plane",
          },
        },
        no_interest: {
          de: "Kein Interesse",
          style: {
            color: "red",
            iconCssClass: "fas fa-check",
          },
        },
        not_encountered: {
          de: "Nicht angetroffen",
          style: {
            color: "blue",
            iconCssClass: "fas fa-times",
          },
        },
        no_need_for_action: {
          de: "Kein Handlungsbedarf",
          style: {
            color: "lightblue",
            iconCssClass: "fas fa-times",
          },
        },
        not_inhabited: {
          de: "Nicht bewohnt",
          style: {
            color: "purple",
            iconCssClass: "fas fa-times",
          },
        },
      },
      requiredPermission: "ROLE_DSN_WRITE_STATUS",
    },
  },
};

export default locale;
